import React from "react"
import Link from "./link"

export default ({ link }) => {
    if (typeof link.external === 'undefined')
        link.external = true
    
    return (
        <li><Link to={link.url} external={link.external}>{link.title}</Link></li>
    )
}
