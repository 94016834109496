import React from "react"
import Column from "./column"
import NavListItem from "./nav-list-item"

export default ({ title, links }) => {
    return (
        <Column>
            {title &&
                <h2 class="h3">{title}</h2>
            }
            <nav class="menu">
                <ul>
                    {links.map(link => <NavListItem link={link} />)}
                </ul>
            </nav>
        </Column>
    )
}
