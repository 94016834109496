import React from "react"
import Layout from "../components/layouts/default"
import SEO from "../components/global/seo"
import Message from "../components/message"
import NavList from "../components/nav-list"

import Icon from "../images/icon-support.svg"

export default () => {
    const contents = [
        { title: 'Installation', 'links': [
            { title: 'Installationsguide',
                url: 'https://setup.meinzugangsdienst.de' },
            { title: 'Aktuelle Installationshinweise',
                url: 'https://setup.meinzugangsdienst.de/hinweise' },
            { title: 'Support-Kit laden',
                url: 'https://setup.meinzugangsdienst.de/supportkit' },
            { title: 'Konnektor Fehlercodes',
                url: 'https://setup.meinzugangsdienst.de/fehlercodes' },
            { title: 'Support bei Twitter',
                url: 'https://twitter.com/ehex_' }
        ]},
        { title: 'Infomaterial', 'links': [
            { title: 'Informationen der KBV zur Telematikinfrastruktur',
                url: 'https://www.kbv.de/media/sp/PraxisWissen_Telematikinfrastruktur.pdf' },
            { title: 'Informationen der KZBV zur Telematikinfrastruktur',
                url: 'https://www.kzbv.de/kzbv2018-praxisinfo-telematikinfrastruktur.download.464c9831cf458d9b5022c934e8b6de99.pdf' },
            { title: 'gematik Informationsblatt "Technische Ausstattung einer medizinischen Einrichtung"',
                url: 'https://www.gematik.de/fileadmin/user_upload/gematik/files/Publikationen/gem_OPB_Infoblatt_Ausstattung_2017-10-IB-TAME_web.pdf' },
            { title: 'gematik Informationsblatt "Anschluss einer medizinischen Einrichtung"',
                url: 'https://fachportal.gematik.de/fileadmin/user_upload/gematik/files/OPB-Infomaterialien/gem_2017-12-IB-AME_anschluss_medizinische_einrichtung_online.pdf' },
            { title: 'gematik "Checkliste Arztpraxis"',
                url: 'https://fachportal.gematik.de/fileadmin/user_upload/gematik/files/OPB-Infomaterialien/gem_2017-12-CL-AP_checkliste_arztpraxis_online.pdf' },
            { title: 'gematik "Checkliste Zahnarztpraxis"',
                url: 'https://fachportal.gematik.de/fileadmin/user_upload/gematik/files/OPB-Infomaterialien/gem_2017-12-CL-ZAP_checkliste_zahnarztpraxis_online.pdf' },
            { title: 'gematik "Checkliste Psychotherapeutenpraxis"',
                url: 'https://fachportal.gematik.de/fileadmin/user_upload/gematik/files/OPB-Infomaterialien/gem_2017-12-CL-PTP_checkliste_psychotherapeutenpraxis_online.pdf' }
        ]},
        { title: 'Datenblätter', 'links': [
            { title: 'Datenblatt secunet konnektor',
                url: 'https://www.secunet.com/fileadmin/user_upload/_temp_/importexport/Print/Factsheets/deutsch/sec_secunet_konnektor_Factsheet_DE.pdf' },
            { title: 'Datenblatt eHealth Tastaturkartenleser Cherry G87-1505',
                url: 'https://www.cherry.de/PDF/DE_CHERRY-eGK-Tastatur_G87-1505.pdf' },
            { title: 'eHealth Kartenterminal Ingenico ORGA 6141 online',
                url: 'https://ingenico.de/healthcare/produkte-und-leistungen/ehealth-kartenterminals-fur-das-deutsche-gesundheitssystem/stationare-ehealth-kartenterminals/orga-6141-online.html' },
            { title: 'Mobiles Kartenterminal Ingenico ORGA 930 M online',
                url: 'https://ingenico.de/healthcare/produkte-und-leistungen/ehealth-kartenterminals-fur-das-deutsche-gesundheitssystem/mobile-ehealth-kartenterminals/orga-930-m-online.html' }
        ]}
    ];
    
    return (
        <Layout>
            <SEO title="Support" description="Support und Hilfestellungen rundum den Telematik-Service Mein Zugangsdienst." />
            <header>
                <Message title="Support" image={<img src={Icon} alt="Support" />} />
            </header>
            <section class="container flexcards">
                {contents.map(object => <NavList title={object.title} links={object.links} />)}
            </section>
        </Layout>
    )
}
